@import '_vars.css';
@import 'animation.css';

*
{
    margin: 0;
    padding: 0;
}

body{
    background-color: var(--bg_color);
    color: var(--color);
    font-family: sans-serif;
    max-height: 100vh;
    max-width: 100vw;
}

.layout
{
    padding-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    max-width: 100vw;
    margin: 0;
    text-align: center;
    width: 100%;
}

.layout h2
{
    font-size: 4em;
    padding-top: 5vh;
    text-transform: uppercase;
}

.block{
    text-align: left;
    padding: 1vh 0;
}

.blockTitle
{
    font-size: 1em;
    font-weight: bold;
}

.blockContent
{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px;
}

.blockElement
{
    opacity: 0.7;
    display: block;
    padding: 10px;
    background-color: gainsboro;
    color: black;
    border-radius: 10%;
    margin: 2.5px;
    text-align: center;
    transition: opacity 1s ease-in-out;
}

.blockElement.skillElement{
    flex: 1 0 auto;
}

.blockElement:hover,.blockElement:active
{
    cursor: pointer;
    opacity: 1;
}

.blockElement.now
{
    background-color: greenyellow;
}

.blockElement.past
{
    background-color: gainsboro;
}

.blockElement.recent
{
    background-color: orange;
}