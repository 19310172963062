.getconnected
{
    background-image: url('/public/svg/connect.svg');
    background-repeat: no-repeat;
    background-position: bottom 5vw right 5vw;
    background-size: 40% 40%;
    background-attachment: scroll;
}

.getconnected.layout
{
    justify-content: flex-start;
}

.getconnected.layout .content
{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 10vh;
    padding-left: 2vw;
    align-self: flex-start;
    text-align: left;
}

.getconnected.layout .content>*
{
    padding: 0.5em;
}

.getconnected.layout .content>*:hover
{
    font-weight: bold;
}

.getconnected.layout .content div
{
    font-size: larger;
}

.getconnected.layout .content div a, a:active, a:hover
{
    color: inherit;
    text-decoration: none;
}

.getconnected.layout .content div img
{
    min-width: 28px;
    max-width: 28px;
    width: 2vw;
}