.hero .logo
{
    border: 1vw solid #ffffff;
    box-sizing: border-box;
    border-radius: 50%;
    height: 20vw;
    width: 20vw;
    min-height: 256px;
    min-width: 256px;
    animation: 2s fade-in linear;
}

.hero .overlay
{
    height: 100%;
    left: 0;
    min-height: 100vh;
    max-width: 100vw;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 2.5rem;
}

.hero
{
    margin-top: 5rem;
}

.hero .overlay video
{
    object-fit: cover;
}

.hero .intro
{
    font-family: "Times New Roman", Times, serif;
    font-size: 8vw;
    font-weight: bold;
    animation: 3s fade-in linear;
}

.hero .tagline
{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3vw;
    font-style: italic;
    animation: 5s fade-in linear;
}

.hero .arrow
{
    height: 5vh;
    width: 5vh;
}

.hero .arrow:first-child
{
    animation: 1s animateArrow 4s linear infinite;
}

.hero .arrow:last-child
{
    animation: 1s animateArrow 4s linear infinite;

}

@media (max-width: 768px){
    .hero .intro
    {
        font-size: 10vw;
    }

    .hero .tagline
    {
        font-size: 8vw;
    }
}