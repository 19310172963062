.about
{
    background-color: var(--bg_color_alt);
    background-attachment: scroll;
    color: var(--color_alt);
}

.about.layout .content
{
    display: flex;
    flex-wrap: wrap;
    padding: 5%;
    text-align: left;
}

 .about.layout .content
{
    text-align: justify;
}

 .about.layout .content ul
{
    padding-left: 3%;
}