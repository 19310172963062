@keyframes animateArrow
{
    0%, 100%
    {
       transform: translateY(-1vh);
    }
    25%, 75%
    {
        transform: translateY(0vh);
    }
    50%
    {
       transform: translateY(1vh)
    }
}

@keyframes fade-in
{
    0%
    {
        opacity: 0;
    }
    100%
    {
        opacity: 1;
    }
}